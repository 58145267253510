<template>
    <div class="users-page">
        <div class="container-fluid">
            <h1>Użytkownicy</h1>
            
            
            <router-link class="mt-3" :to="{name: 'AdminUserAdd' }" ><button class="btn btn-secondary" >Dodaj</button></router-link>

            <div class="d-flex justify-content-between mt-3">
                <div class="pr-2">
                    <button class="btn" :class="{ 'btn-primary': selectedCustomers, 'btn-secondary': !selectedCustomers}"  @click.prevent="setUserTypeCustomer()">Klienci</button>
                    <button class="btn ml-2" :class="{ 'btn-primary': selectedAdmins, 'btn-secondary': !selectedAdmins}" @click.prevent="setUserTypeAdmin()">Administratorzy</button>
                    <button class="btn ml-2" :class="{ 'btn-primary': selectedDrivers, 'btn-secondary': !selectedDrivers}" @click.prevent="setUserTypeDriver()">Kierowcy</button>
                </div>
                <div class="pr-2 flex-grow-1">
                    <input type="text" class="form-control" v-model="filter.search" placeholder="Szukaj" />
                </div>
                <b-pagination v-if="totalRows>perPage" 
                    v-model="currentPage"
                    :totalRows="totalRows"
                    :per-page="perPage"
                ></b-pagination>
            </div>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
            <b-table v-else :items="myDataProvider" :fields="fields" :filter="filter" :show-empty="true"
                    class="mt-2" outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                    :current-page="currentPage" :per-page="perPage"
                    sort-by="id"
                    :sort-desc="true"
                >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                </template>

                <template #cell(name)="data">
                    {{ data.item.name }} {{ data.item.surname }}
                </template>

                <template #cell(active)="data">
                    {{ data.item.active == 1 ? 'tak' : 'nie' }}
                </template>

                <template #cell(options)="data">
                    <router-link :to="{name: 'AdminUserPreview',  params: { userId: data.item.id } }" >
                        <button class="btn btn-secondary" >Zobacz</button>
                    </router-link>
                    <router-link class="ml-2" :to="{name: 'AdminUserEdit',  params: { userId: data.item.id } }" >
                        <button class="btn btn-secondary" >Edytuj</button>
                    </router-link>
                    <button class="btn btn-primary ml-2" @click.prevent="remove(data.item)" >Usuń</button>
                </template>
                
            </b-table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

export default {
    name: 'adminUsers',
    data() {
        return {
            isLoading: false,
            perPage: 25,
            currentPage: 1,
            totalRows: 0,
            userType: USER_typeCustomer,
            filter: {
                search: '',
                userType: USER_typeCustomer,
            },
            fields: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Imię i nazwisko', key: 'name', sortable: true },
                { label: 'Email', key: 'email', sortable: true },
                { label: 'Telefon', key: 'phone', sortable: true },
                { label: 'Aktywny', key: 'active', sortable: false },
                { label: '', key: 'options', sortable: false }
            ],
        }
    },
    computed: {
        selectedAdmins() {
            return this.filter.userType == USER_typeAdmin;
        },
        selectedDrivers() {
            return this.filter.userType == USER_typeDriver;
        },
        selectedCustomers() {
            return this.filter.userType == USER_typeCustomer;
        }
    },
    methods: {
        setUserTypeAdmin() {
            this.filter.userType = USER_typeAdmin;
        },
        setUserTypeDriver() {
            this.filter.userType = USER_typeDriver;
        },
        setUserTypeCustomer() {
            this.filter.userType = USER_typeCustomer;
        },
        myDataProvider(ctx) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            const promise = axios.post('/admin/users/' + this.filter.userType, {
                "perPage": ctx.perPage,
                "currentPage": ctx.currentPage,
                "sortBy": ctx.sortBy,
                "asc": ctx.sortDesc ? false : true,
                "filter": ctx.filter.search
            });

            return promise.then(result => {
                const items = result.data.users;
                this.totalRows = result.data.count || 0;
                return items || []
            })

        },
        remove(user){
            
            this.$bvModal.msgBoxConfirm('Czy chcesz usunąc "'+ user.name + user.surname +'"?')
            .then(value => {
                if ( value ) {
                    this.isLoading = true;
                    axios.post('/admin/user/remove', { id: user.id })
                    .then( () => {
                        this.isLoading =  false;
                    }).catch( (error) => {
                        console.log('error', error);
                        this.$bvToast.toast(`Błąd usuwania użytkownika`, {
                            title: 'BŁĄD',
                            autoHideDelay: 5000,
                            solid: true,
                            variant: 'danger',
                            appendToast: true,
                            toaster: 'b-toaster-top-right'
                        })
                        this.isLoading =  false;
                    });
                }
            });
            
        }
    }
}
</script>
